import {
    Abstract
} from './Abstract';

export class ProductFamily extends Abstract {
    constructor(session) {
        super('comercial/ProductFamily', session);
        this.name = null;
        this.key_name = null;
    }
}
